import * as Turbo from '@hotwired/turbo'
import './App/image.js'
import './App/main.js'

import 'sneat-bootstrap-html-admin-template-free/assets/vendor/libs/jquery/jquery.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/libs/popper/popper.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/bootstrap.js'

import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/helpers.js'
import 'sneat-bootstrap-html-admin-template-free/assets/vendor/js/menu.js'

import TomSelect from "tom-select/dist/js/tom-select.base.js"
import TomSelect_remove_button from 'tom-select/dist/js/plugins/remove_button.js';
import "trix"
import "@rails/actiontext"
import pdt360DegViewer from "./pdt360DegViewer.js"
import noUiSlider from 'nouislider';

Turbo.start()
document.addEventListener("turbo:load", onLoad);
document.addEventListener("turbo:submit-end", onLoad);
let menu, animate;
function onLoad() {


    let modalImageButtons = document.querySelectorAll('.modal-image-delete-button');
    modalImageButtons.forEach(e => {
      e.addEventListener('click', (event) => {
        if ($($($(e).parents()[4])).find("img")) $($(e).parents()[4]).find("img").remove()

        $($(e).parents()[4]).find("input").value = null
        $($(e).parents()[4]).find("input").removeClass("is-valid");
        $($(e).parents()[4]).find(`.upload-delete-trigger`).removeClass("d-none");

        fetch("/backend/image/" + e.dataset.id, {
          method: 'DELETE',
          headers: {
            "X-CSRF-Token": e.dataset.authenticityToken,
            "Content-Type": "application/json"
          }
        })
      })
    })


    $(".360-viewer").each(function() {
      pdt360DegViewer($(this).attr("id"), $(this).data("count"), $(this).data("path"), 'webp', false, $(this).data("autoplay")==="true", true, false, true, false, false);
    })

    $(".slider").each(function() {
      let min = $("#"+$(this).data("element")+"_start")
      let max = $("#"+$(this).data("element")+"_end")
      let slider = noUiSlider.create(this, {
          start: [min.val(), max.val()],
          range: {
            'min': [$(this).data("min")],
            'max': [$(this).data("max")]
          },
          connect: true,
          tooltips: true,
          format: {
              to: function (value) {
                  return value + '';
              },
              from: function (value) {
                  return Number(value.replace('', ''));
              }
          },
          step: 1,
          pips: {
            mode: 'range',
            density:3
          }
      });
      slider.on('change.one', function (e) {
        min.val(e[0])
        max.val(e[1])
      });
    })




  TomSelect.define('remove_button', TomSelect_remove_button);

  $(".input-tags").each(function() {
    new TomSelect(this, {
      plugins: {
        remove_button:{
          title: 'Entfernen',
        }
      },
      hidePlaceholder: true,
      valueField: 'name',
      labelField: 'name',
      searchField: 'name',
      options: $(this).data("options"),
      persist: false,
      create: true,
      render:{
        option_create: function(data, escape) {
          return '<div class="create"><strong>' + escape(data.input) + '</strong> hinzufügen</div>';
        },
        no_results:function(data,escape){
          return '';
        },
      }
    });
  })


  $(".input-select").each(function() {
    new TomSelect(this, {
      plugins: {
        remove_button:{
          title: 'Entfernen',
        }
      },
      hidePlaceholder: true,
      valueField: 'id',
      labelField: 'title',
      searchField: 'title',
      options: $(this).data("options"),
      persist: false,
      create: false,
      render:{
        no_results:function(data,escape){
          return '<div class="no-results">Keine Artikel für <strong>"'+escape(data.input)+'"</strong> gefunden.</div>';
        },
      }
    });
  })


  // Initialize menu
  //-----------------

  let layoutMenuEl = document.querySelectorAll('#layout-menu');
  layoutMenuEl.forEach(function (element) {
    menu = new Menu(element, {
      orientation: 'vertical',
      closeChildren: false
    });
    // Change parameter to true if you want scroll animation
    window.Helpers.scrollToActive((animate = false));
    window.Helpers.mainMenu = menu;
  });

  // Initialize menu togglers and bind click on each
  let menuToggler = document.querySelectorAll('.layout-menu-toggle');
  menuToggler.forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      window.Helpers.toggleCollapsed();
    });
  });

  // Display menu toggle (layout-menu-toggle) on hover with delay
  let delay = function (elem, callback) {
    let timeout = null;
    elem.onmouseenter = function () {
      // Set timeout to be a timer which will invoke callback after 300ms (not for small screen)
      if (!Helpers.isSmallScreen()) {
        timeout = setTimeout(callback, 300);
      } else {
        timeout = setTimeout(callback, 0);
      }
    };

    elem.onmouseleave = function () {
      // Clear any timers set to timeout
      document.querySelector('.layout-menu-toggle').classList.remove('d-block');
      clearTimeout(timeout);
    };
  };
  if (document.getElementById('layout-menu')) {
    delay(document.getElementById('layout-menu'), function () {
      // not for small screen
      if (!Helpers.isSmallScreen()) {
        document.querySelector('.layout-menu-toggle').classList.add('d-block');
      }
    });
  }

  // Display in main menu when menu scrolls
  let menuInnerContainer = document.getElementsByClassName('menu-inner'),
    menuInnerShadow = document.getElementsByClassName('menu-inner-shadow')[0];
  if (menuInnerContainer.length > 0 && menuInnerShadow) {
    menuInnerContainer[0].addEventListener('ps-scroll-y', function () {
      if (this.querySelector('.ps__thumb-y').offsetTop) {
        menuInnerShadow.style.display = 'block';
      } else {
        menuInnerShadow.style.display = 'none';
      }
    });
  }

  // Init helpers & misc
  // --------------------

  // Init BS Tooltip
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Accordion active class
  const accordionActiveFunction = function (e) {
    if (e.type == 'show.bs.collapse' || e.type == 'show.bs.collapse') {
      e.target.closest('.accordion-item').classList.add('active');
    } else {
      e.target.closest('.accordion-item').classList.remove('active');
    }
  };

  const accordionTriggerList = [].slice.call(document.querySelectorAll('.accordion'));
  const accordionList = accordionTriggerList.map(function (accordionTriggerEl) {
    accordionTriggerEl.addEventListener('show.bs.collapse', accordionActiveFunction);
    accordionTriggerEl.addEventListener('hide.bs.collapse', accordionActiveFunction);
  });

  // Auto update layout based on screen size
  window.Helpers.setAutoUpdate(true);

  // Toggle Password Visibility
  window.Helpers.initPasswordToggle();

  // Manage menu expanded/collapsed with templateCustomizer & local storage
  //------------------------------------------------------------------

  // If current layout is horizontal OR current window screen is small (overlay menu) than return from here
  if (window.Helpers.isSmallScreen()) {
    return;
  }

  // If current layout is vertical and current window screen is > small

  // Auto update menu collapsed/expanded based on the themeConfig
  window.Helpers.setCollapsed(true, false);


  
  let modalButtons = document.querySelectorAll('.modal-delete-button');
  modalButtons.forEach(e => {
    e.addEventListener('click', (event) => {
      event.preventDefault();
      fetch(e.dataset.url, {
        method: e.dataset.method || 'DELETE',
        headers: {
          "X-CSRF-Token": e.dataset.authenticityToken,
          "Content-Type": "application/json"
        }
      }).then(response => {
        if (response.redirected) {
          window.location.href = response.url;
        }
      })
    })
  })
}